import Vue from "vue"
import BootstrapVue from "bootstrap-vue"

import "@/styles.scss"

// import "bootstrap/dist/css/bootstrap.min.css"
// import "bootstrap-vue/dist/bootstrap-vue.css"

Vue.use(BootstrapVue, {
  BOverlay: {
    variant: "white",
  },
})
