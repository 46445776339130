import Vue from "vue"
import VueRouter from "vue-router"
import i18n from "@/i18n"
import store from "@/store/index"

Vue.use(VueRouter)

import Home from "../views/Home.vue"

/* webpackChunkName info */
// route level code-splitting
// this generates a separate chunk (about.[hash].js) for this route
// which is lazy-loaded when the route is visited.
const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    meta: {
      title: "dashboard.pagename",
      auth: true,
    },
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "../views/Dashboard.vue"),
  },
  {
    path: "/myhealth",
    name: "MyHealth",
    meta: {
      title: "myhealth.pagename",
      auth: true,
    },
    component: () =>
      import(/* webpackChunkName: "myhealth" */ "../views/MyHealth.vue"),
  },
  {
    path: "/challenge",
    name: "Challenge",
    meta: {
      title: "missions.pagename",
      auth: true,
    },
    component: () =>
      import(/* webpackChunkName: "challenge" */ "../views/Challenge.vue"),
  },
  {
    path: "/challenge/:id/version/:version/instance/:instance",
    name: "View challenge",
    meta: {
      title: "viewmission.pagename",
      auth: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "challengeinstance" */ "../views/SpecificChallenge.vue"
      ),
  },
  {
    path: "/challenge/add",
    name: "Add challenge",
    meta: {
      title: "missions.add_mission",
      auth: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "addChallenge" */ "../views/RecommendedMissions.vue"
      ),
  },
  {
    path: "/teams",
    name: "Teams",
    meta: {
      title: "teams.pagename",
      auth: true,
    },
    component: () =>
      import(/* webpackChunkName: "teams" */ "../views/Teams.vue"),
  },
  {
    path: "/team",
    name: "My team",
    meta: {
      title: "teams.my_team",
      auth: true,
    },
    component: () =>
      import(/* webpackChunkName: "team" */ "../views/MyTeam.vue"),
  },
  {
    path: "/team/:id",
    name: "Checkout team",
    meta: {
      title: "teams.pagename",
      auth: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "checkoutTeam" */ "../views/CheckoutTeam.vue"
      ),
  },
  {
    path: "/gamerules",
    name: "Gamerules",
    meta: {
      title: "gamerules.pagename",
      auth: true,
    },
    component: () =>
      import(/* webpackChunkName: "gamerules" */ "../views/Gamerules.vue"),
  },
  {
    path: "/account",
    name: "Account",
    meta: {
      title: "account.pagename",
      auth: true,
    },
    component: () =>
      import(/* webpackChunkName: "account" */ "../views/Account.vue"),
  },
  {
    path: "/authprovider",
    name: "Apps-Devices",
    meta: {
      title: "dataproviders.pagename",
      auth: true,
    },
    component: () =>
      import(/* webpackChunkName: "appsDevices" */ "../views/Apps-Devices.vue"),
  },
  {
    path: "/authprovider/:provider",
    name: "Apps-Devices provider",
    meta: {
      title: "dataproviders.pagename",
      auth: true,
    },
    component: () =>
      import(/* webpackChunkName: "appsDevices" */ "../views/Apps-Devices.vue"),
  },
  {
    path: "/system-admin",
    name: "SystemAdmin",
    meta: {
      title: "System Admin",
      auth: true,
    },
    component: () =>
      import(/* webpackChunkName: "systemAdmin" */ "../views/SystemAdmin.vue"),
  },
  {
    path: "/admin",
    name: "Admin panel",
    meta: {
      title: "admin.pagename",
      auth: true,
      userShouldBeAdmin: true,
    },
    beforeEnter: (to, from, next) => {
      if (store.state.user_is_admin) {
        next()
      } else {
        next("/dashboard")
      }
    },
    component: () =>
      import(/* webpackChunkName: "admin" */ "../views/Admin.vue"),
  },
  {
    path: "/login",
    name: "Login",
    meta: {
      title: "login.pagename",
    },
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login.vue"),
  },
  {
    path: "/logout",
    name: "Log out",
    meta: {
      title: "logout",
      auth: true,
    },
    component: () =>
      import(/* webpackChunkName: "logout" */ "../views/Logout.vue"),
  },
  {
    path: "/register",
    name: "Register",
    meta: {
      title: "register.pagename",
    },
    component: () =>
      import(/* webpackChunkName: "register" */ "../views/Register.vue"),
  },
  {
    path: "/register-now/organization",
    name: "RegisterOrganization",
    meta: {
      title: "register_organization.pagename",
    },
    component: () =>
      import(
        /* webpackChunkName: "registerOrganization" */ "../views/RegisterOrganization.vue"
      ),
  },
  {
    path: "/registration-confirmation",
    name: "RegisterConfirmation",
    meta: {
      title: "register_organization.confirmation",
    },
    component: () =>
      import(
        /* webpackChunkName: "registerConfirmation" */ "../views/RegisterConfirmation.vue"
      ),
  },
  {
    path: "/single-org-enrollment",
    name: "SingleOrgEnrollment",
    meta: {
      title: "register_organization.pagename",
    },
    component: () =>
      import(
        /* webpackChunkName: "registerOrganization" */ "../views/SingleOrgEnrollment.vue"
      ),
  },

  {
    // Change to `path: "/:catchAll(.*)"` for Vue 3
    path: "*",
    name: "Not found",
    meta: {
      title: "not_found",
    },
    component: () =>
      import(/* webpackChunkName: "notFound" */ "../views/NotFound.vue"),
  },
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem("user")

  // If the user is already logged in, redirect to MyHealth
  if (to.matched.some(record => !record.meta.auth) && loggedIn) {
    next("/myhealth")
    return
  }
  // If the route requires authentication and the user isn't logged in, redirect to login
  if (to.matched.some(record => record.meta.auth) && !loggedIn) {
    //! TODO: FIX
    // if (from.matched.some((record) => record.meta.auth)) {
    //   next(`/login?url=${encodeURIComponent(from.fullPath)}`)
    // } else {
    //   next(`/login`)
    // }
    next(`/login`)
    return
  }
  next()
})

router.afterEach(({ name, meta }) => {
  Vue.nextTick(() => {
    if (meta.title) {
      document.title = `ReachingApp - ${i18n.t(meta.title)}`
    } else if (name) {
      document.title = `ReachingApp - ${name}`
    }
  })
})

export default router
