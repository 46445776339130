var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex w-100",attrs:{"id":"app"}},[(_vm.isAuth && _vm.isLogged)?_c('div',[_c('b-sidebar',{attrs:{"id":"navbar","sidebar-class":"border-right primary","no-header":"","shadow":"","body-class":"position-relative","bg-variant":"secondary","text-variant":"light","visible":"","no-close-on-route-change":"","z-index":"150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('b-icon-x',{staticClass:"position-absolute cursor-pointer",staticStyle:{"right":"0.5rem","top":"0.5rem"},attrs:{"font-scale":"2","tabindex":"0"},on:{"click":hide}}),_c('div',{staticClass:"d-flex flex-column justify-content-between h-100 side-bar-border"},[_c('div',{staticClass:"px-4.5 py-5 flex-fill"},[_c('div',{staticClass:"text-center"},[_c('b-link',{staticClass:"d-inline-block text-decoration-none",attrs:{"to":"/account"}},[_c('b-img',{staticClass:"mb-3",staticStyle:{"object-fit":"cover"},attrs:{"rounded":"circle","height":"68","width":"68","src":("" + (_vm.userInfo.avatar_file_path) + (_vm.userInfo.avatar_file_name))},on:{"error":function (event) {
                      event.target.src = _vm.$images.noAvatar
                    }}}),_c('div',{staticClass:"text-white"},[_c('b',[_vm._v(_vm._s(_vm.userInfo.first_name)+" "+_vm._s(_vm.userInfo.last_name))])])],1)],1),_c('hr',{staticClass:"border-terciary"}),_c('div',[_c('div',{staticClass:"text-uppercase mb-2 font-weight-semibold"},[_vm._v(_vm._s(_vm.$t("menu")))]),_c('b-nav',{staticClass:"text-terciary vertical-menu",attrs:{"vertical":""}},[_c('b-nav-item',{attrs:{"to":"/dashboard","exact":""}},[_c('b-icon-grid1x2'),_c('span',[_vm._v(_vm._s(_vm.$t("dashboard.pagename")))])],1),_c('b-nav-item',{attrs:{"to":"/myhealth"}},[_c('b-icon-journal-bookmark'),_c('span',[_vm._v(_vm._s(_vm.$t("myhealth.pagename")))])],1),_c('b-nav-item',{attrs:{"to":"/challenge"}},[_c('b-icon-clipboard-check'),_c('span',[_vm._v(_vm._s(_vm.$t("missions.pagename")))])],1),_c('b-nav-item',{attrs:{"to":"/teams","link-classes":!_vm.isOwnTeam && _vm.$route.fullPath.trim().startsWith('/team/')
                      ? 'router-link-exact-active router-link-active'
                      : ''}},[_c('b-icon-people'),_c('span',[_vm._v(_vm._s(_vm.$t("teams.pagename")))])],1),_c('b-nav-item',{attrs:{"to":"/team","exact":"","link-classes":_vm.isOwnTeam
                      ? 'router-link-exact-active router-link-active'
                      : ''}},[_c('b-icon-person-badge'),_c('span',[_vm._v(_vm._s(_vm.$t("teams.my_team")))])],1),_c('b-nav-item',{attrs:{"to":"/gamerules"}},[_c('b-icon-controller'),_c('span',[_vm._v(_vm._s(_vm.$t("gamerules.pagename")))])],1)],1)],1)]),_c('div',{staticClass:"bg-terciary text-light px-4.5 py-3"},[_c('div',{staticClass:"text-uppercase mb-2 font-weight-semibold"},[_vm._v(" "+_vm._s(_vm.$t("account.pagename"))+" ")]),_c('b-nav',{staticClass:"text-light vertical-menu-bottom",attrs:{"vertical":""}},[_c('b-nav-item',{on:{"click":_vm.logout}},[_c('b-icon-box-arrow-right'),_c('span',[_vm._v(_vm._s(_vm.$t("logout")))])],1),_c('b-nav-item',{attrs:{"to":"/account"}},[_c('b-icon-gear'),_c('span',[_vm._v(_vm._s(_vm.$t("account.settings")))])],1),_c('b-nav-item',{attrs:{"to":"/authprovider"}},[_c('b-icon-smartwatch'),_c('span',[_vm._v(_vm._s(_vm.$t("dataproviders.pagename")))])],1),(_vm.userInfo.user_is_admin == 'Y')?_c('b-nav-item',{attrs:{"to":"/admin"}},[_c('b-icon-wrench'),_c('span',[_vm._v(_vm._s(_vm.$t("admin.pagename")))])],1):_vm._e()],1)],1)])]}}],null,false,1966603623),model:{value:(_vm.sidemenu),callback:function ($$v) {_vm.sidemenu=$$v},expression:"sidemenu"}}),_c('b-icon-chevron-right',{directives:[{name:"b-toggle",rawName:"v-b-toggle.navbar",modifiers:{"navbar":true}}],staticClass:"position-fixed cursor-pointer d-none d-md-block",staticStyle:{"top":"50%","left":"0.5rem","line-height":"0","z-index":"50","filter":"drop-shadow(0 0px 5px rgb(0, 0, 0))"},attrs:{"font-scale":"1.5","tabindex":"0"}})],1):_vm._e(),_c('main',{staticClass:"w-100",class:_vm.returnClass(),staticStyle:{"transition":"margin 0.3s ease-in-out"},style:(_vm.isAuth &&
        _vm.isLogged &&
        _vm.sidemenu &&
        !['xs', 'sm', 'md'].includes(_vm.$breakpoint()) &&
        "margin-left: 320px"),attrs:{"id":"content"}},[(_vm.isAuth && _vm.isLogged)?_c('div',{staticClass:"navbar-dark d-flex d-md-none justify-content-start py-2 px-2 bg-primary menu-rounded"},[_c('b-navbar-toggle',{staticClass:"rounded-sm",attrs:{"target":"navbar"}})],1):_vm._e(),_c('b-overlay',{attrs:{"show":_vm.transitionLoading}},[_c('router-view')],1),_c('NotificationAlert')],1),_c('b-modal',{attrs:{"visible":_vm.showProfiling,"centered":"","scrollable":"","hide-footer":"","hide-header-close":_vm.$route.query.profiling !== undefined,"no-close-on-backdrop":_vm.$route.query.profiling !== undefined,"no-close-on-esc":_vm.$route.query.profiling !== undefined,"title":_vm.$t('profiling.profiling'),"title-class":"h3","lazy":"","size":"lg","busy":_vm.profilingLoading}},[_c('b-overlay',{attrs:{"show":_vm.profilingLoading}},[_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.$route.query.profiling === undefined),expression:"$route.query.profiling === undefined"}],staticClass:"text-muted"},[_vm._v(" "+_vm._s(_vm.$t("profiling.update_profiling"))+" ")]),_c('DomainProfile',{attrs:{"visible":true,"dismissButton":false},on:{"before":function($event){_vm.profilingLoading = true},"after":function($event){_vm.profilingLoading = false},"confirmed":function($event){_vm.showProfiling = false}}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }