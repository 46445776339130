<template>
  <div id="app" class="d-flex w-100">
    <!-- Sidebar -->
    <div v-if="isAuth && isLogged">
      <b-sidebar
        id="navbar"
        sidebar-class="border-right primary"
        no-header
        shadow
        body-class="position-relative"
        bg-variant="secondary"
        text-variant="light"
        visible
        v-model="sidemenu"
        no-close-on-route-change
        z-index="150"              
      >
        <template #default="{ hide }">
          <b-icon-x
            font-scale="2"
            class="position-absolute cursor-pointer"
            style="right: 0.5rem; top: 0.5rem"
            tabindex="0"
            @click="hide"
          />

          <!-- Top part -->
          <div class="d-flex flex-column justify-content-between h-100 side-bar-border">
            <div class="px-4.5 py-5 flex-fill">
              <div class="text-center">
                <b-link
                  to="/account"
                  class="d-inline-block text-decoration-none"
                >
                  <b-img
                    class="mb-3"
                    rounded="circle"
                    style="object-fit: cover"
                    height="68"
                    width="68"
                    :src="
                      `${userInfo.avatar_file_path}${userInfo.avatar_file_name}`
                    "
                    @error="
                      event => {
                        event.target.src = $images.noAvatar
                      }
                    "
                  />
                  <div class="text-white">
                    <b>{{ userInfo.first_name }} {{ userInfo.last_name }}</b>
                  </div>
                </b-link>
              </div>

              <hr class="border-terciary" />

              <div>
                <div class="text-uppercase mb-2 font-weight-semibold">{{ $t("menu") }}</div>

                <b-nav vertical class="text-terciary vertical-menu">
                  <b-nav-item to="/dashboard" exact>
                    <b-icon-grid1x2 />
                    <span>{{ $t("dashboard.pagename") }}</span>
                  </b-nav-item>
                  <b-nav-item to="/myhealth">
                    <b-icon-journal-bookmark />
                    <span>{{ $t("myhealth.pagename") }}</span>
                  </b-nav-item>
                  <b-nav-item to="/challenge">
                    <b-icon-clipboard-check />
                    <span>{{ $t("missions.pagename") }}</span>
                  </b-nav-item>
                  <b-nav-item
                    to="/teams"
                    :link-classes="
                      !isOwnTeam && $route.fullPath.trim().startsWith('/team/')
                        ? 'router-link-exact-active router-link-active'
                        : ''
                    "
                  >
                    <b-icon-people />
                    <span>{{ $t("teams.pagename") }}</span>
                  </b-nav-item>
                  <b-nav-item
                    to="/team"
                    exact
                    :link-classes="
                      isOwnTeam
                        ? 'router-link-exact-active router-link-active'
                        : ''
                    "
                  >
                    <b-icon-person-badge />
                    <span>{{ $t("teams.my_team") }}</span>
                  </b-nav-item>
                  <b-nav-item to="/gamerules">
                    <b-icon-controller />
                    <span>{{ $t("gamerules.pagename") }}</span>
                  </b-nav-item>
                </b-nav>
              </div>
            </div>

            <!-- Bottom part -->
            <div class="bg-terciary text-light px-4.5 py-3">
              <div class="text-uppercase mb-2 font-weight-semibold">
                {{ $t("account.pagename") }}
              </div>
              <b-nav vertical class="text-light vertical-menu-bottom">
                <b-nav-item @click="logout">
                  <b-icon-box-arrow-right />
                  <span>{{ $t("logout") }}</span>
                </b-nav-item>
                <b-nav-item to="/account">
                  <b-icon-gear />
                  <span>{{ $t("account.settings") }}</span>
                </b-nav-item>
                <b-nav-item to="/authprovider">
                  <b-icon-smartwatch />
                  <span>{{ $t("dataproviders.pagename") }}</span>
                </b-nav-item>
                <b-nav-item to="/admin" v-if="userInfo.user_is_admin == 'Y'">
                  <b-icon-wrench />
                  <span>{{ $t("admin.pagename") }}</span>
                </b-nav-item>
              </b-nav>
            </div>
          </div>
        </template>
      </b-sidebar>

      <b-icon-chevron-right
        class="position-fixed cursor-pointer d-none d-md-block"
        style="
          top: 50%;
          left: 0.5rem;
          line-height: 0;
          z-index: 50;
          filter: drop-shadow(0 0px 5px rgb(0, 0, 0));
        "
        v-b-toggle.navbar
        font-scale="1.5"
        tabindex="0"
      />
    </div>

    <main
      id="content"
      style="transition: margin 0.3s ease-in-out"
      :style="
        isAuth &&
          isLogged &&
          sidemenu &&
          !['xs', 'sm', 'md'].includes($breakpoint()) &&
          `margin-left: 320px`
      "
      class="w-100"
      :class="returnClass()"
    >
      <div
        v-if="isAuth && isLogged"
        class="navbar-dark d-flex d-md-none justify-content-start py-2 px-2 bg-primary menu-rounded"
      >
        <b-navbar-toggle target="navbar" class="rounded-sm"></b-navbar-toggle>
      </div>

      <b-overlay :show="transitionLoading">
        <router-view />
      </b-overlay>
      <NotificationAlert />
    </main>

    <b-modal
      :visible="showProfiling"
      centered
      scrollable
      hide-footer
      :hide-header-close="$route.query.profiling !== undefined"
      :no-close-on-backdrop="$route.query.profiling !== undefined"
      :no-close-on-esc="$route.query.profiling !== undefined"
      :title="$t('profiling.profiling')"
      title-class="h3"
      lazy
      size="lg"
      :busy="profilingLoading"
    >
      <b-overlay :show="profilingLoading">
        <p class="text-muted" v-show="$route.query.profiling === undefined">
          {{ $t("profiling.update_profiling") }}
        </p>
        <DomainProfile
          :visible="true"
          :dismissButton="false"
          @before="profilingLoading = true"
          @after="profilingLoading = false"
          @confirmed="showProfiling = false"
        />
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import {
  BIconBoxArrowRight,
  BIconGrid1x2,
  BIconJournalBookmark,
  BIconClipboardCheck,
  BIconPeople,
  BIconController,
  BIconGear,
  BIconX,
  BIconChevronRight,
  BIconPersonBadge,
  BIconWrench,
  BIconSmartwatch,
} from "bootstrap-vue"
import { mapGetters } from "vuex"
import axios from "axios"
import NotificationAlert from "@/components/NotificationAlert.vue"
import DomainProfile from "./components/DomainProfile.vue"

export default {
  components: {
    BIconBoxArrowRight,
    BIconGrid1x2,
    BIconJournalBookmark,
    BIconClipboardCheck,
    BIconPeople,
    BIconController,
    BIconGear,
    BIconX,
    BIconChevronRight,
    BIconPersonBadge,
    BIconSmartwatch,
    NotificationAlert,
    BIconWrench,
    DomainProfile,
  },
  data() {
    return {
      sidemenu: true,
      showProfiling: false,
      transitionLoading: false,
      profilingLoading: false,
    }
  },

  created() {
    this.$router.beforeEach((to, from, next) => {
      // if mobile and route path chaned
      if (
        ["xs", "sm", "md"].includes(this.$breakpoint()) &&
        to.path != from.path
      )
        this.sidemenu = false
      if (to.meta.auth && !from.meta.auth) this.$store.dispatch("fetchUserInfo")
      this.transitionLoading = true

      if (
        from.path.startsWith("/login") &&
        this.$route.query.profiling !== undefined
      ) {
        // show profiling on route change from login and ?profiling param is given
        setTimeout(() => {
          this.showProfiling = true
        }, 500)
      }
      next()
    })
    this.$router.afterEach(() => {
      this.transitionLoading = false
    })

    document.getElementById("favicon").href = this.$images.favicon
    window.addEventListener("resize", this.updateBreakpoint)

    this.unwatch = this.$store.watch(
      (state, getters) => getters.breakpoint,
      newValue => {
        this.sidemenu = !["xs", "sm", "md"].includes(newValue)
      }
    )

    if (!this.$store.getters.ownTeam && this.$store.getters.isLogged) {
      axios.get("/team?group_id=my").then(response => {
        const ownTeamId = response.data?.data?.[0]?.id
        if (ownTeamId) {
          this.$store.dispatch("setOwnTeam", ownTeamId)
        }
      })
    }
  },
  beforeDestroy() {
    this.unwatch()
  },
  destroyed() {
    window.removeEventListener("resize", this.updateBreakpoint)
  },
  mounted() {
    this.$router.onReady(() => {
      if (this.isAuth) {
        this.$store.dispatch("fetchUserInfo")
        this.$store.dispatch("toShowProfiling").then(value => {
          this.showProfiling = value
          if (this.$route.query.profiling !== undefined) {
            // show profiling if ?profiling is a given query param
            this.showProfiling = true
          }
        })
      }
    })
  },

  computed: {
    ...mapGetters([
      "isLogged",
      "userIsAdmin",
      "userInfo",
      "ownTeam",
      "breakpoint",
    ]),
    /** @return {Boolean} */
    isAuth() {
      return Boolean(this.$route?.meta?.auth)
    },
    /** @return {Boolean} */
    isOwnTeam() {
      // if on team page
      if (this.$route.path.startsWith("/team/")) {
        // return if team id is same as own
        return this.$store.getters.ownTeam == this.$route.params.id
      }
      return false
    },
  },

  methods: {
    updateBreakpoint() {
      this.$store.dispatch("setBreakpoint", this.$breakpoint())
    },
    returnClass() {
      let x = ""
      if (this.isAuth && this.isLogged && this.$route.name == "Gamerules") {
        x = ""
      } else if (this.isAuth && this.isLogged) {
        x = "px-sm-4"
      }
      return x
    },
    logout() {
      this.$store.dispatch("logout")
    },
  },
}
</script>

<style scoped>
@media (min-width: 576px) {
  .menu-rounded {
    border-bottom-right-radius: 11px;
    border-bottom-left-radius: 11px;
  }
}
</style>
