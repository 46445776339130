import Vue from "vue"
import Vuex from "vuex"
import axios from "axios"
import i18n from "@/i18n"
const confetti = require("canvas-confetti")

Vue.use(Vuex)

// Utility function to get the user's timezone
function getUserTimezone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}


export default new Vuex.Store({
  state: {
    user: localStorage.getItem("user"),
    lang: localStorage.getItem("lang"),
    user_is_admin: localStorage.getItem("userIsAdmin"),
    userInfo: {},
    latestNotification: new Date(localStorage.getItem("latestNotification")),
    ownTeam: localStorage.getItem("ownTeam"),
    breakpoint: "",
    GlobalSnackbars: [],
    timezone: getUserTimezone(), // Initialize timezone in state
  },

  
  mutations: {
    setLang(state, language) {
      if (typeof language == "string" && language.length > 0) {
        state.lang = language
        localStorage.setItem("lang", language)
      } else {
        state.lang = null
        localStorage.removeItem("lang")
      }
    },

    setUserData(state, userData) {
      state.user = userData
      localStorage.setItem("user", JSON.stringify(userData))
      axios.defaults.headers.common.Authorization = `Bearer ${userData.access_token}`
      axios.defaults.headers.common["X-timezone"] = state.timezone; // Set timezone header
    },

    setTimezone(state, timezone) {
      state.timezone = timezone;
      axios.defaults.headers.common["X-timezone"] = timezone; // Set timezone header
    },
    PUSH_TOAST_MESSAGE(state, toast) {
      if (toast) {
        state.GlobalSnackbars.push(toast)
      }
    },
    IS_USER_ADMIN(state, value) {
      if (value) {
        state.user_is_admin = true
        localStorage.setItem("userIsAdmin", true)
      }
    },

    clearUserData(state) {
      state.user = null
      state.lang = null
      state.user_is_admin = null
      state.GlobalSnackbar = {
        text: "",
        type: "",
      }
      localStorage.removeItem("lang")
      localStorage.removeItem("user")
      localStorage.removeItem("userIsAdmin")
      localStorage.removeItem("latestNotification")
      location.reload()
    },

    setUserInfo(state, value) {
      state.userInfo = value
    },
    setLatestNotifications(state, date) {
      state.latestNotification = date
      localStorage.setItem("latestNotification", date)
    },
    setOwnTeam(state, value) {
      state.ownTeam = value
      localStorage.setItem("ownTeam", value)
    },
    setBreakpoint(state, value) {
      state.breakpoint = value
    },
  },

  actions: {
    async showConfetti() {
      let count = 200
      let defaults = {
        origin: { y: 0.7 },
        disableForReducedMotion: true,
      }
      const particles = [
        {
          particleRatio: 0.25,
          spread: 26,
          startVelocity: 55,
        },
        {
          particleRatio: 0.2,
          spread: 60,
        },
        {
          particleRatio: 0.35,
          spread: 100,
          decay: 0.91,
          scalar: 0.8,
        },
        {
          particleRatio: 0.1,
          spread: 120,
          startVelocity: 25,
          decay: 0.92,
          scalar: 1.2,
        },
        {
          particleRatio: 0.1,
          spread: 120,
          startVelocity: 45,
        },
      ]
      for (const opts of particles) {
        confetti.default(
          Object.assign({}, defaults, opts, {
            particleCount: Math.floor(count * opts.particleRatio),
          })
        )
      }
    },

    changeLanguage({ commit, getters }, language) {
      if (getters.languages.map(e => e.code).includes(language.toLowerCase())) {
        commit("setLang", language)
        i18n.locale = getters.language
        axios.defaults.headers.common["X-Localization"] = getters.language
        return
      }
    },

    async login({ commit, dispatch }, credentials) {
      const { data } = await axios.post("/login", credentials)
      commit("setUserData", data)
      dispatch("setTimezone"); // Dispatch setTimezone after login
    },
    logout({ commit }) {
      commit("clearUserData")
    },
    async fetchUserInfo({ commit, dispatch }) {
      try {
        const response = await axios.get("/user")
        let info = response.data.data
        commit("setUserInfo", info)
        if (info.language) dispatch("changeLanguage", info.language)
      } catch ({ response, name, message }) {
        dispatch("addToastMessage", {
          type: "danger",
          text: response?.data?.error_message ?? `${name} ${message}`,
        })
      }
    },
    async toShowProfiling({ commit, dispatch, state }) {
      let today = new Date()
      today.setHours(0, 0, 0, 0) // clear time component
      // if last notification is a valid date
      if (
        state.latestNotification && // not falsy
        Object.prototype.toString.call(state.latestNotification) ===
          "[object Date]" && // is of type Date
        !isNaN(state.latestNotification) // is not Invalid Date
      ) {
        // it is assumed latestNotification does not have time component (only date)
        let dateDiff = Math.ceil(
          (today - state.latestNotification) / (1000 * 60 * 60 * 24)
        )
        // if difference in days between today and when the latest notification was shown less than a day, don't show notification
        if (dateDiff < 1) {
          return false
        }
      }
      // reset latest shown time to today
      commit("setLatestNotifications", today)

      // check if notification should be showed
      try {
        const response = await axios.get("/notifications")
        let notifications = response.data.data
        if (notifications["re-profile"] == "Y") {
          return true
        }
      } catch ({ response }) {
        dispatch("addToastMessage", {
          type: "danger",
          text: response.data.error_message,
        })
      }
      // show notification
      return false
    },

    /**
     *
     * @param {*} param0
     * @param {{type: string, text: string, dismissSecs: number}} toast
     */
    addToastMessage({ commit }, toast) {
      commit("PUSH_TOAST_MESSAGE", toast)
    },
    UserIsAdmin({ commit }, value) {
      commit("IS_USER_ADMIN", value)
    },
    setOwnTeam({ commit }, value) {
      commit("setOwnTeam", value)
    },
    setBreakpoint({ commit }, value) {
      commit("setBreakpoint", value)
    },
    setTimezone({ commit }) {
      commit("setTimezone", getUserTimezone()); // Action to set timezone
    }    
  },

  getters: {
    breakpoint: state => state.breakpoint,
    ownTeam: state => state.ownTeam,
    userInfo: state => state.userInfo,
    isLogged: state => Boolean(state.user),
    userIsAdmin: state => Boolean(state.user_is_admin),
    language: (state, getters) => {
      let lang =
        state.lang ||
        (navigator.language || navigator.userLanguage).split("-")[0]
      lang = lang.toLowerCase()
      document.getElementsByTagName("html")[0].lang = lang
      if (lang == "sv") lang = "se"
      if (getters.languages.map(e => e.code).includes(lang)) {
        return lang
      }
      return "en"
    },
    years: () => {
      const nowYear = new Date().getFullYear()
      return Array(100)
        .fill(0)
        .map((_, i) => nowYear - i)
    },
    genders: () => [
      {
        value: "M",
        text: "genders.male",
      },
      {
        value: "F",
        text: "genders.female",
      },
      {
        value: "O",
        text: "genders.other",
      },
    ],
    languages: () => [
      { code: "en", text: "English" },
      { code: "se", text: "Svenska" },
    ],
  },
})
