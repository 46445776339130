import "mutationobserver-shim"
import Vue from "vue"
import "./plugins/bootstrap-vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import i18n from "./i18n"
import VueObserveVisibility from "vue-observe-visibility"
import axios from "axios"
import VueGtag from "vue-gtag"

Vue.config.productionTip = false

Vue.use(
  VueGtag,
  {
    config: { id: process.env.VUE_APP_GTAG_ID },
  },
  router
)

Vue.use(VueObserveVisibility)
Vue.prototype.$cssVar = function(varname) {
  const style = getComputedStyle(document.body)
  return style.getPropertyValue(varname).trim()
}

/**
 * @example !['xs', 'sm', 'md'].includes($breakpoint())
 * @returns The current screen size breakpoint
 */
Vue.prototype.$breakpoint = function() {
  const style = getComputedStyle(document.body)
  const breakvars = [
    "--breakpoint-xs",
    "--breakpoint-sm",
    "--breakpoint-md",
    "--breakpoint-lg",
    "--breakpoint-xl",
  ]
  for (const name of breakvars) {
    if (
      window.matchMedia(
        `screen and (max-width: ${style.getPropertyValue(name).trim()})`
      ).matches
    ) {
      let afterSplit = name.split("-")
      return afterSplit[afterSplit.length - 1] ?? "xl"
    }
  }
  return "xl"
}

Vue.prototype.$links = {
  // Must include trailing slash
  homepage: process.env.VUE_APP_HOMEPAGE,
}

Vue.prototype.$images = {
  // Must include trailing slash
  aws_root: process.env.VUE_APP_AWS_ROOT,
  favicon: process.env.VUE_APP_FAVICON,
  logo: process.env.VUE_APP_LOGO,
  noAvatar: process.env.VUE_APP_NO_AVATAR,
  loginBg: process.env.VUE_APP_LOGIN_BG,
  registerBg: process.env.VUE_APP_REGISTER_BG,
  registerOrganizationBg: process.env.VUE_APP_REGISTER_ORGANIZATION_BG,
  googlefit: process.env.VUE_APP_GOOGLE_FIT,
  fitbit: process.env.VUE_APP_FITBIT,
}

store.dispatch("setTimezone");

new Vue({
  i18n,
  router,
  store,

  created() {
    // Handles if a user refreshes the page, should not be redirected to login again
    const userInfo = this.$store.state.user

    if (userInfo) {
      try {
        const userData = JSON.parse(userInfo)
        this.$store.commit("setUserData", userData)
      } catch (error) {
        // If malformed userInfo from localstorage
        this.$store.dispatch("logout")
      }
    }
    axios.interceptors.response.use(
      response => response,
      error => {
        if (
          this.$store.getters.isLogged &&
          error.response?.status === 401 &&
          error.response?.data?.message == "Unauthenticated."
        ) {
          // Logging out because of unauthenticated request
          this.$store.dispatch("logout")
        }
        return Promise.reject(error)
      }
    )
    if (process.env.VUE_APP_USE_MOCK == "true") {
      axios.defaults.baseURL = process.env.VUE_APP_MOCK_URL
    } else {
      axios.defaults.baseURL = process.env.VUE_APP_BASE_URL
    }
    axios.defaults.headers.common[
      "X-Localization"
    ] = this.$store.getters.language
  },
  render: h => h(App),
}).$mount("#app")
