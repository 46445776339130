<template>
  <div class="notification-message">
    <b-alert
      v-for="(snack, i) in snackbar"
      :key="`${i}${snack.type}${snack.text}`"
      :variant="snack.type"
      dismissible
      fade
      :show="snack.dismissCountDown"
      @dismissed="snack.dismissCountDown = 0"
      @dismiss-count-down="
        dismissCountDown => {
          snack.dismissCountDown = dismissCountDown
        }
      "
    >
      {{
        !snack.text && (snack.type == "danger" || snack.type == "warning")
          ? $t("unknown_error")
          : snack.text
      }}
    </b-alert>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dismissSecs: 5,
      /** @type {[{type: string, text: string, dismissCountDown: number}]} */
      snackbar: [],
    }
  },

  created() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === "PUSH_TOAST_MESSAGE") {
        this.snackbar = state.GlobalSnackbars.map(elem => {
          elem.dismissCountDown = elem.dismissSecs || this.dismissSecs
          return elem
        })
      }
    })
  },
}
</script>

<style scoped>
.notification-message {
  position: fixed;
  bottom: 0;
  right: 0;
  margin-right: 15px;
  width: 320px;
  z-index: 10000;
}

/* Custom colors for b-alert variants */
.alert-success {
  background-color: #00C782 !important; /* Replace with your custom success color */
  color: #ffffff !important;
  border-color: #009E68 !important;
  font-weight: 600;
}

.alert-danger {
  background-color: #E63375 !important; /* Replace with your custom danger color */
  color: #ffffff !important;
  border-color: #B0225A !important;
  font-weight: 600;
}

</style>
