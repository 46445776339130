<template>
  <b-form @submit.prevent="putDomains">
    <div v-for="domain in domains" :key="domain.domain_id" class="mb-5">
      <b-row no-gutters>
        <b-col class="rounded overflow-hidden shadow">
          <div
            class="p-4 text-white"
            :style="{
              backgroundImage: `linear-gradient(90deg, rgba(0, 0, 0, 0.8) 50px, rgba(0, 0, 0, 0.1) 250px), url(${domain.domain_media_file})`,
              backgroundSize: 'cover',
            }"
          >
            <h2 class="mb-5">
              {{ domain.domain_name }}
            </h2>
          </div>
          <div class="py-4 px-4 text-terciary">
            <p>
              {{ domain.domain_description }}
            </p>

            <hr />

            <b-form-group
              v-for="model in domain.domain_models"
              :key="model.model_id"
              :label="model.model_question"
              :label-for="`domainmodel-${model.model_id}`"
            >
              <b-form-input
                type="range"
                min="1"
                max="10"
                :id="`domainmodel-${model.model_id}`"
                :name="`domainmodel-${model.model_id}`"
                :class="!model.touched && error ? 'error-shadow' : ''"
                @click="model.touched = true"
                @input="model.touched = true"
                v-model="model.current_level"
                number
              />
            </b-form-group>
          </div>
        </b-col>
      </b-row>
    </div>

    <b-form-invalid-feedback :state="error ? false : null" class="mb-3">
      {{ error }}
    </b-form-invalid-feedback>

    <b-row>
      <b-col v-if="dismissButton">
        <b-button block variant="primary" to="/myhealth">
          {{ $t("profiling.later") }}
        </b-button>
      </b-col>
      <b-col>
        <b-button block type="submit" variant="primary">
          {{ $t("profiling.submit") }}
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import axios from "axios"

export default {
  props: {
    visible: { type: Boolean, default: false },
    dismissButton: { type: Boolean, default: true },
  },

  watch: {
    visible: {
      immediate: true,
      handler(val) {
        if (val) {
          this.fetchDomains()
        }
      },
    },
  },

  data() {
    return {
      /** @type {Array<Object>} */
      domains: [],
      error: "",
    }
  },

  methods: {
    async fetchDomains() {
      this.error = ""

      try {
        const response = await axios.get("/domainprofile")
        this.domains = response.data.data
        return true
      } catch ({ name, message, response }) {
        this.error = response.data?.message ?? `${name}: ${message}`
        return false
      }
    },

    async putDomains() {
      try {
        this.$emit("before")
        this.error = ""
        let changed_models = []

        // Add domains that have changed to `changed_models`
        this.domains.map(domain =>
          domain.domain_models.map(model => {
            if (model.touched) changed_models.push(model)
          })
        )
        // If changed domains are less that total amount of domain models
        if (
          changed_models.length !=
          this.domains.reduce(
            (acc, domain) => acc + domain.domain_models.length,
            0
          )
        ) {
          this.error = this.$t("profiling.all_domains")
          return false
        }

        try {
          await axios.put(
            "/domainprofile",
            changed_models.map(model => ({
              model_id: model.model_id,
              current_level: model.current_level,
            }))
          )
        } catch ({ name, message, response }) {
          this.error = response.data?.message ?? `${name}: ${message}`
          return false
        }
        this.$emit("confirmed")
      } finally {
        this.$emit("after")
      }
    },
  },
}
</script>
